<script setup>
const emit = defineEmits(["select-bible", "select-book"]);

const { bibles, htmlElements } = useApp();
const activatedBible = ref(null);
const selectedBook = ref(null);
</script>
<template>
  <v-screen-group
    :ref="(el) => (htmlElements.parallelScreensEl = el)"
    v-slot="{ goNext, goPrev, goHome }"
    class="v-screen-parallel"
  >
    <v-screen name="bibles">
      <v-navigation-header
        class="mx-2"
        label="Compare"
        @goback="htmlElements.homeScreensEl.goPrev()"
      ></v-navigation-header>

      <div class="parallel-bible-wr">
        <div class="bible-section1">
          <VParallelBibleToolbar
            :modelValue="bibles.singleBible"
            @selectBible="
              activatedBible = bibles.singleBible;
              goNext('select-bible');
            "
            @selectBook="
              activatedBible = bibles.singleBible;
              goNext('select-book');
            "
          />
          <div class="bible-translate">
            <VSingleBible
              :singleBible="bibles.singleBible"
              :elementId="`parallelBible1`"
            />
          </div>
        </div>

        <div class="bible-section2">
          <VParallelBibleToolbar
            :modelValue="bibles.parallelBible"
            @selectBible="
              activatedBible = bibles.parallelBible;
              goNext('select-bible');
            "
            @selectBook="
              activatedBible = bibles.parallelBible;
              goNext('select-book');
            "
          />
          <div class="bible-translate">
            <VSingleBible
              :singleBible="bibles.parallelBible"
              :elementId="`parallelBible2`"
            />
          </div>
        </div>
      </div>
    </v-screen>

    <v-screen name="select-bible">
      <v-screen-select-bible
        @select="
          ({ bibleCode }) => {
            useEvent('parallelbible.selectBible', {
              singleBible: activatedBible,
              bibleCode,
            });
            goPrev();
          }
        "
        @goback="goPrev()"
      ></v-screen-select-bible>
    </v-screen>
    <v-screen name="select-book">
      <v-screen-select-books
        :singleBible="activatedBible"
        @select="
          ({ bookModel }) => {
            selectedBook = bookModel;
            goNext('select-chapter');
          }
        "
        @goback="goPrev()"
      ></v-screen-select-books>
    </v-screen>
    <v-screen name="select-chapter">
      <v-screen-select-chapter
        :items="selectedBook?.bookChapters"
        @select="
          ({ chapterNum }) => {
            useEvent('parallelbible.selectChapter', {
              singleBible: activatedBible,
              bookCode: selectedBook.bookCode,
              chapterNum,
            });
            goHome();
          }
        "
        @goback="goPrev()"
      ></v-screen-select-chapter>
    </v-screen>
  </v-screen-group>
</template>
<style scoped>
.v-screen-parallel {
  height: 100%;
}
.parallel-bible-wr {
  height: 100%;
  background-color: var(--root-white);
}
.bible-section1 {
  overflow: hidden;
  height: 50%;
}
.bible-section2 {
  overflow: hidden;
  height: 50%;
}
.bible-translate {
  overflow-y: hidden;
  /* overflow-y: auto; */
  /* height: calc(50% - 40px); */
  /* padding: 50px 20px 70px 20px; */
  box-sizing: border-box;
  margin-top: 40px;
  height: calc(50vh - 90px);
}
</style>
<style>
.v-screen:has(.parallel-bible-wr) {
  overflow-y: hidden;
}
</style>
